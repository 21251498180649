<template lang="pug">
div.container
  div(v-if="!globalState.isLoggedIn").justify-content-center.text-center
    h2.mb-5.text-warning I'm afraid I can't let you do that, Dave.

    h3.mb-4 401 - Not Authenticated
    p The place you tried to visit requires you to be logged in... and you're not. Maybe your session timed out?
    p.mb-5 Don't panic, tho! You can always login again.

    a(href="/api/auth/login")
      img(src="https://platform.slack-edge.com/img/sign_in_with_slack.png" srcset="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x")

  div(v-else).justify-content-center.text-center
    h2.mb-5.text-warning I'm afraid I can't let you do that, {{globalState.username}}.

    h3.mb-4 401 - Not Authorized
    p Oops, the place you tried to visit is confidential and requires a higher access level than "{{globalState.roleName}}".
    p.mb-5 How about you ask one of our admins to hook you up with some of that sweet, sweet access?

    h4 Admins
    div(v-for="admin in admins" :key="admin._id")
      strong {{admin.name}}
</template>

<script>
import globalState from '@/main.js'

export default {
  data: function () {
    return {
      globalState,
      admins: []
    }
  },
  mounted: function () {
    if (this.globalState.isLoggedIn) {
      this.axios.get('/api/roles/admins')
        .then(res => (this.admins = res.data))
        .catch(err => (this.$notify({
          group: 'error',
          type: 'error',
          title: 'Fetching admins failed',
          text: err.response.data,
          duration: -1
        })))
    }
  }
}
</script>
